import * as React from "react";
import { Link } from "gatsby";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ButtonLink extends React.Component {
  render() {
    const { url, type, text, extraClass, icon } = this.props;

    return (
      <>
        <Link to={url} target="_self">
          <button className={type + " " + extraClass + " ctaButton"}>
            {icon && <FontAwesomeIcon icon={icon} size="1x" className="pr-1" />}
            {text}
          </button>
        </Link>
      </>
    );
  }
}

export default ButtonLink;
