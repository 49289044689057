/**
 * Foursource page
 * https://foursource.com/contact-us/contact
 */

import * as React from "react";
import { withTrans } from "../../i18n/withTrans";

import { pageTexts } from "../../content/contact-us";

// components
import Layout from "../../components/layout";
import TitleSeparator from "../../components/titleSeparator";
import ButtonLink from "../../components/_partials/_buttonLink";

class contactPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
  }

  render() {
    return (
      <>
        <Layout pageName="contact-us.contact" whiteMenu={true}>
          <div className="pt-menu">
            <div className="paddingSidesBig pt-100 pb-100 text-center">
              <TitleSeparator info={this.text.thankYou}></TitleSeparator>
              <div className="row justify-content-center pt-30 pb-75">
                <ButtonLink
                  text={this.text.backHome.text}
                  url={this.text.backHome.url}
                  type={this.text.backHome.type}
                  extraClass={this.text.backHome.extraClass}
                ></ButtonLink>
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }
}

export default withTrans(contactPage);
